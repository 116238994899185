

















import { defineComponent, computed } from '@vue/composition-api';
import { ArrowRightSmallIcon, ScanQrCodeIcon } from '@nimiq/vue-components';
import { AddressType, useAddressStore } from '../stores/Address';
import { useAccountStore } from '../stores/Account';
import { CryptoCurrency } from '../lib/Constants';
import { useBtcAddressStore } from '../stores/BtcAddress';
import { useWindowSize } from '../composables/useWindowSize';
import { ColumnType, useActiveMobileColumn } from '../composables/useActiveMobileColumn';
import { useUsdcAddressStore } from '../stores/UsdcAddress';

export default defineComponent({
    setup(props, context) {
        const { activeAddressInfo, addressInfos } = useAddressStore();
        const { activeCurrency, activeAccountInfo, hasBitcoinAddresses } = useAccountStore();
        const { accountBalance: btcBalance } = useBtcAddressStore();
        const { addressInfo: usdcAddressInfo } = useUsdcAddressStore();
        const { isMobile } = useWindowSize();
        const { activeMobileColumn } = useActiveMobileColumn();

        function nimOrBtcOrUsdc<T>(nim: T, btc: T, usdc: T): T {
            switch (activeCurrency.value) {
                case CryptoCurrency.NIM: return nim;
                case CryptoCurrency.BTC: return btc;
                case CryptoCurrency.USDC: return usdc;
                default: throw new Error('Invalid active currency');
            }
        }

        const hasMultipleReceivableAddresses = computed(() => (
            addressInfos.value.filter(({ type }) => type === AddressType.BASIC).length > 1));

        function receive() {
            if (isMobile.value
                && activeMobileColumn.value !== ColumnType.ADDRESS
                && (hasMultipleReceivableAddresses.value || hasBitcoinAddresses.value)
            ) {
                // redirect to the address selector
                context.root.$router.push('/receive');
            } else {
                context.root.$router.push(nimOrBtcOrUsdc('/receive/nim', '/receive/btc', '/receive/usdc'));
            }
        }

        const hasMultipleSendableAddresses = computed(() =>
            activeAccountInfo.value && activeAccountInfo.value.addresses.length > 1);

        function send() {
            if (isMobile.value
                && activeMobileColumn.value !== ColumnType.ADDRESS
                && (hasMultipleSendableAddresses.value || hasBitcoinAddresses.value)
            ) {
                // redirect to the address selector
                context.root.$router.push('/send');
            } else {
                context.root.$router.push(nimOrBtcOrUsdc('/send/nim', '/send/btc', '/send/usdc'));
            }
        }

        const sendDisabled = computed(() => context.root.$route.path !== '/' && nimOrBtcOrUsdc(
            !activeAddressInfo.value || !activeAddressInfo.value.balance,
            !btcBalance.value,
            !usdcAddressInfo.value || !usdcAddressInfo.value.balance,
        ));

        return {
            receive,
            send,
            sendDisabled,
        };
    },
    components: {
        ArrowRightSmallIcon,
        ScanQrCodeIcon,
    },
});
