






























import { defineComponent, computed } from '@vue/composition-api';
import { Identicon, LockLockedIcon, CircleSpinner } from '@nimiq/vue-components';
import Amount from './Amount.vue';
import FiatConvertedAmount from './FiatConvertedAmount.vue';
import VestingIcon from './icons/VestingIcon.vue';
import { AddressInfo, AddressType } from '../stores/Address';
import { CryptoCurrency } from '../lib/Constants';
import BitcoinIcon from './icons/BitcoinIcon.vue';
import UsdcIcon from './icons/UsdcIcon.vue';

export default defineComponent({
    props: {
        addressInfo: {
            type: Object as () => AddressInfo |
                Pick<AddressInfo, 'address' | 'label' | 'balance'> & {
                    type: CryptoCurrency.BTC | CryptoCurrency.USDC,
                },
            required: true,
        },
    },
    setup(props) {
        const currentCurrency = computed(() => {
            switch (props.addressInfo.type) {
                case CryptoCurrency.BTC: return CryptoCurrency.BTC;
                case CryptoCurrency.USDC: return CryptoCurrency.USDC;
                default: return CryptoCurrency.NIM;
            }
        });

        return {
            AddressType,
            CryptoCurrency,
            currentCurrency,
        };
    },
    components: {
        Identicon,
        LockLockedIcon,
        Amount,
        FiatConvertedAmount,
        VestingIcon,
        BitcoinIcon,
        UsdcIcon,
        CircleSpinner,
    },
});
