import { render, staticRenderFns } from "./BtcTransactionList.vue?vue&type=template&id=52468017&scoped=true&"
import script from "./BtcTransactionList.vue?vue&type=script&lang=ts&"
export * from "./BtcTransactionList.vue?vue&type=script&lang=ts&"
import style0 from "./BtcTransactionList.vue?vue&type=style&index=0&id=52468017&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52468017",
  null
  
)

export default component.exports